import React, { useState, useEffect } from 'react';

const MigrationNotice = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isDismissed, setIsDismissed] = useState(() => {
    return localStorage.getItem('migrationNoticeDismissed') === 'true';
  });

  const START_DATE = new Date('2024-12-30');
  const END_DATE = new Date('2025-06-01');
  const URGENT_NOTICE_DAYS = 30;

  const getDaysRemaining = () => {
    const today = new Date();
    const diffTime = END_DATE - today;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const hasStarted = () => {
    const today = new Date();
    return today >= START_DATE;
  };

  if (!hasStarted()) return null;

  const isUrgentPeriod = getDaysRemaining() <= URGENT_NOTICE_DAYS;

  const handleDismiss = () => {
    localStorage.setItem('migrationNoticeDismissed', 'true');
    setIsDismissed(true);
  };

  if (isDismissed && !isUrgentPeriod) return null;

  return (
    <div className={`fixed top-0 left-0 right-0 z-50 ${isUrgentPeriod ? 'bg-red-500' : 'bg-yellow-500'}`}>
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <p className="text-white font-medium">
              {isUrgentPeriod ? (
                <>
                  <span className="font-bold">Urgent:</span> This site will be discontinued in {getDaysRemaining()} days. 
                  Please migrate to our new platform at eznotemanagerpro.com
                </>
              ) : (
                'We\'re moving! EzNoteManager is transitioning to eznotemanagerpro.com on June 1st, 2025.'
              )}
            </p>
          </div>
          <div className="flex items-center">
            <a
              href="https://eznotemanagerpro.com"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 bg-white text-black px-4 py-1 rounded hover:bg-gray-100"
            >
              Migrate Now
            </a>
            {!isUrgentPeriod && (
              <button
                onClick={handleDismiss}
                className="text-white hover:text-gray-200"
              >
                ✕
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MigrationNotice; 